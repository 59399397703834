import { color, space, typography } from 'styled-system';
import styled from 'styled-components';
import { Link as InertiaLink } from '@inertiajs/react';

const Link = styled(InertiaLink)`
    ${space}
    ${typography}
    ${color}
`;

Link.defaultProps = {
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'primary.base',
};

export default Link;
